import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import {markItemDismissed, markItemReviewed, showActionError, showActionSuccess} from '../../data/copilot';
import {BLACK_100, GREEN_95} from '../../theme';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {useDismissDiscrepancyMutation} from '../../data/copilotApi';
import Loading from '../Loading';
import {trackEvent} from '../../util';
import IconButton from '@mui/material/IconButton';

const styles = {
  card: {
    selected: {
      card: {
        borderRadius: '0.5rem',
        border: '1px solid #DDE0DE',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
        padding: '1rem',
      },
      text1: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '160%',
      },
      text2: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '160%',
      },
      text3: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '160%',
      },
      button: {
        borderRadius: '1.125rem',
        border: '1px solid #D3D6D4',
        paddingX: '1rem',
        paddingY: '0.375rem',
      },
      dismissButton: {
        borderRadius: '1.125rem',
        border: '1px solid #D3D6D4',
        paddingX: '1rem',
        paddingY: '0.375rem',
      },
    },
    unselected: {
      text1: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '160%',
        opacity: 0.7,
      },
      text2: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '160%',
      },
    },
    itemLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    itemSubLabel: {
      color: '#9F7119',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    discrepancyLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    discrepancyValue: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    footingShouldBeLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      fontStyle: 'italic',
    },
  },
  dismiss: {
    header: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    formControlLabel: {
      '.MuiFormControlLabel-label': {
        color: BLACK_100,
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
      },
      marginRight: 0,
      alignItems: 'flex-start',
      '.MuiRadio-root': {
        paddingLeft: 0,
        paddingRight: '0.5rem',
        paddingY: 0,
      },
    },
    textField: {
      '.MuiOutlinedInput-root': {
        height: '2rem',
        borderRadius: '0.25rem',
        border: '1px solid #DDE0DE',
        paddingY: 0,
        '&.Mui-focused fieldset': {
          borderColor: GREEN_95,
        },
      },
    },
  },
  component: {
    button: {
      borderRadius: '0.5rem',
      border: '1px solid #0F1E24;',
      minHeight: '2rem',
    },
    dismissButton: {
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE;',
      paddingHorizontal: '0.75rem',
      paddingVertical: '0.375rem',
      minWidth: 'inherit',
      '.MuiButton-startIcon': {
        marginRight: '0.25rem',
        marginLeft: 0,
      },
    },
    buttonLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
  },
}

const DEFAULT_DISMISS_REASON = 'no_reason';

const CopilotCard = ({documentId, checkType, setOpenItem, openItem, idx, item, isLoading}) => {
  const dispatch = useDispatch();
  const [dismissReason, setDismissReason] = useState(DEFAULT_DISMISS_REASON);
  const [dismissOtherDetail, setDismissOtherDetail] = useState('');
  const [dismissDiscrepancy, {isLoading: isDismissLoading, isSuccess: isDismissSuccess, isError: isDismissError, error: dismissError}] = useDismissDiscrepancyMutation();
  const [showDismissal, setShowDismissal] = useState(false);
  const [isProcessingDismissal, setProcessingDismissal] = useState(false);
  const [dismissStartTimestamp, setDismissStartTimestamp] = useState(null);
  const copilot = useSelector(state => state.copilot);
  const {currentOrg} = useSelector(state => state.session);

  useEffect(() => {
    if (isDismissSuccess) {
      setProcessingDismissal(false);
      setDismissReason(DEFAULT_DISMISS_REASON);
      setDismissOtherDetail('');
      dispatch(markItemDismissed({checkType, id: item.id, copilotActionMessage: 'Thank you for your feedback!'}));
      trackEvent('Dismissed Discrepancy Completed', {jobId: copilot[checkType].jobId, discrepancyId: item.id, reason: dismissReason, otherReason: dismissOtherDetail}, new Date().getTime() - dismissStartTimestamp);
      setDismissStartTimestamp(null);
      setShowDismissal(false);
    } else if (isDismissError) {
      console.error('Error dismissing discrepancy', {jobId: copilot[checkType].jobId, org: currentOrg.orgId, error: dismissError});
      setProcessingDismissal(false);
      trackEvent('Dismissed Discrepancy Failed', {jobId: copilot[checkType].jobId, discrepancyId: item.id, reason: dismissReason, otherReason: dismissOtherDetail, error: dismissError.status}, new Date().getTime() - dismissStartTimestamp);
      setDismissStartTimestamp(null);
      dispatch(showActionError({copilotErrorMessage: 'There was an error recording your response. Please try again later.'}));
    }
  }, [isDismissError, isDismissSuccess]);

  const _handleDismissReasonChange = event => {
    setDismissReason(event.target.value);
    setDismissOtherDetail('');
  }

  const _popover = (
    <Popover id="popover-basic">
      <Popover.Body style={{padding: 0}}>
        <Stack direction={'column'} spacing={'0.75rem'}>
          <Stack direction={'column'} spacing={'1rem'} padding={'1rem'}>
            <Typography style={styles.dismiss.header}>Dismiss for a reason?</Typography>
            <RadioGroup
              name="dismiss-buttons-group"
              value={dismissReason}
              onChange={_handleDismissReasonChange}
            >
              <Stack direction={'column'} spacing={'1rem'}>
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="no_reason" control={<Radio size={'small'}/>} label="No reason" />
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="value_not_present" control={<Radio size={'small'}/>} label="Displayed value isn’t present in my document" />
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="calculation_incorrect" control={<Radio size={'small'} />} label="Calculation is incorrect" />
                <Stack direction={'column'} spacing={'0.375rem'}>
                  <FormControlLabel sx={styles.dismiss.formControlLabel} value="other" control={<Radio size={'small'} />} label="Other reason" />
                  {dismissReason === 'other' && (
                    <Box paddingLeft={'1.5rem'}>
                      <TextField
                        sx={styles.dismiss.textField}
                        variant={'outlined'}
                        fullWidth={true}
                        value={dismissOtherDetail}
                        onChange={event => setDismissOtherDetail(event.target.value)}
                        placeholder={'Share details (optional) ...'}
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
        <Divider orientation={'horizontal'} sx={{height: 0.0625, opacity: '100'}} />
        <Box display={'flex'} padding={'1rem'}>
          <Button
            variant={'outlined'}
            fullWidth={false}
            sx={styles.component.button}
            onClick={() => {
              setProcessingDismissal(true);
              setDismissStartTimestamp(new Date().getTime());
              const _data = {
                document_id: documentId,
                dismissed_cards: {
                  footings: [],
                  inconsistencies: [],
                },
              };
              _data.dismissed_cards[item.type] = [{
                id: item.id,
                reason: dismissReason,
                other_reason: dismissOtherDetail,
              }];

              dismissDiscrepancy({
                data: _data,
              });
            }}
          >
            <Loading loading={isProcessingDismissal} />
            {!isProcessingDismissal && (
              <Typography style={styles.component.buttonLabel}>Dismiss</Typography>
            )}
          </Button>
        </Box>
      </Popover.Body>
    </Popover>
  )

  const _containsDateString = value => {
    const _date = new Date(value);
    return !isNaN(_date.getTime());
  }

  const _getYearFromString = value => {
    const _date = new Date(value);
    return _date.getFullYear().toString();
  }

  const _getLabel = () => {
    let _year = '';
    let _label = '';
    if (_containsDateString(item.column_header)) {
      _year = _getYearFromString(item.column_header);
      _label = item.line_item;
    } else if (_containsDateString(item.line_item)) {
      _year = _getYearFromString(item.line_item);
      _label = item.column_header;
    } else {
      return `${item.column_header} ${item.line_item}`;
    }

    if (!_.isNil(_year)) {
      return `${_year} ${_label}`;
    }
    return _label;
  }

  if (openItem === idx) {
    return (
      <Stack direction={'column'} spacing={'0.5rem'} style={styles.card.selected.card}>
        <Stack direction={'column'}>
          <Typography style={styles.card.selected.text1}>Footing error found on</Typography>
          <Typography style={styles.card.selected.text2}>{_getLabel()}</Typography>
          <Typography style={styles.card.selected.text3}>Correct math = {item.calculated_value}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={'0.5rem'} flex={1}>
          <IconButton
            aria-label={'Mark complete'}
            variant={'outlined'}
            sx={styles.card.selected.button}
            onClick={() => {
              dispatch(showActionSuccess());
              dispatch(markItemReviewed({checkType, id: item.id}));
            }}
            disabled={isLoading || isDismissLoading}
          >
            <CheckIcon sx={{width: '1rem', height: '1rem'}} />
          </IconButton>
          <Tooltip
            title={'Dismiss suggestion'}
            placement={'top'}
          >
            <Box>
              <OverlayTrigger
                trigger="click"
                placement="bottom-end"
                flip={true}
                rootClose
                overlay={_popover}
                show={showDismissal}
                onToggle={() => setShowDismissal(!showDismissal)}
              >
                <IconButton
                  variant={'outlined'}
                  disabled={isLoading || isDismissLoading}
                  sx={styles.card.selected.dismissButton}
                >
                  <CloseIcon sx={{width: '1rem', height: '1rem'}} />
                </IconButton>
              </OverlayTrigger>
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
    )
  } else {
    return (
      <React.Fragment>
        <Stack direction={'column'} padding={'1rem'} marginTop={'0 !important'} onClick={() => setOpenItem(idx)} sx={{cursor: 'pointer'}}>
          <Typography style={styles.card.unselected.text1}>Footing error found on</Typography>
          <Typography style={styles.card.unselected.text2}>{_getLabel()}</Typography>
        </Stack>
        {openItem !== idx + 1 && (
          <Box paddingX={'0.5rem'} marginTop={'0 !important'}>
            <Divider orientation={'horizontal'} height={'0.625rem'} sx={{opacity: '100', marginTop: 0, paddingX: '0.5rem'}}/>
          </Box>
        )}
      </React.Fragment>
    )
  }
};

CopilotCard.displayName = 'CopilotCard';

CopilotCard.propTypes = {
  documentId: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  setOpenItem: PropTypes.func.isRequired,
  openItem: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withLDConsumer()(CopilotCard);
