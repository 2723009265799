import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {BLACK_100, WHITE_100, COPILOT_DRAWER_WIDTH} from '../../theme';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AiStarIcon from '../../assets/ai-star.svg';
import ThumbsUpIcon from '../../assets/thumbs-up.svg';
import {
  useCreateJobMutation,
  useLazyGetJobStatusQuery,
  useLazyGetJobResultUrlQuery,
} from '../../data/copilotApi';
import {reset, setIsVisible, setPayload} from '../../data/copilot';
import TimeAgo from '../TimeAgo';
import _ from 'lodash';
import Card from './Card';
import CardV1 from './CardV1';
import {useParams} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {APP_HEADER_HEIGHT} from '../../App';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {useFlags} from 'launchdarkly-react-client-sdk';
// import {sleep} from '../util';

const HEADER_HEIGHT = '2.25rem';

const styles = {
  header: {
    label: {
      color: '#043B34',
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
  },
  selection: {
    text2: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '160%',
      textTransform: 'uppercase',
    },
    button: {
      label: {
        color: '#054A41',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '160%',
      },
      styling: {
        borderRadius: '0.5rem',
        border: '1px solid #DDE0DE',
        padding: '0.25rem 0.75rem',
        gap: '0.375rem',
        height: '1.875rem',
        width: '4.5rem',
      },
    },
  },
  runCard: {
    title: {
      color: '#043B34',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '160%',
    },
    button: {
      label: {
        color: '#054A41',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '160%',
      },
      styling: {
        borderRadius: '0.5rem',
        border: '1px solid #DDE0DE',
        padding: '0.25rem 0.75rem',
        gap: '0.375rem',
        width: '3.125rem',
        height: '1.75rem',
      },
    },
    count: {
      color: '#043B34',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '160%',
    },
    label: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '160%',
    },
  },
  footer: {
    footerLabel: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '160%',
    },
  },
  body: {
    currentResults: {
      label: {
        color: '#043B34',
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '160%',
      },
      chip: {
        backgroundColor: '#043B34',
        borderRadius: '1rem',
        height: '1.25rem',
        gap: '0.625rem',
        color: WHITE_100,
        fontSize: '0.75rem',
        lineHeight: '160%',
        fontWeight: 700,
      },
    },
    noResultsLabel: {
      color: '#043B34',
      textAlign: 'center',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '160%',
    },
    noResultsSublabel: {
      color: '#0F1E24',
      opacity: 0.6,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      textAlign: 'center',
    },
    welcomeLabel: {
      color: BLACK_100,
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
      textAlign: 'center',
    },
    introLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      opacity: 0.8,
      textAlign: 'center',
    },
    reviewButton: {
      borderRadius: '0.5rem',
      background: 'linear-gradient(0deg, #0F1E24 0%, #0F1E24 100%), #FFF;',
      gap: '0.625rem',
      height: '2.25rem',
    },
    reviewButtonLabel: {
      color: WHITE_100,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
    },
    errorLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
    },
    openItemsLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    asOfLabel: {
      color: BLACK_100,
      textAlign: 'right',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    footerLabel: {
      color: BLACK_100,
      fontSize: '0.6875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      opacity: 0.5,
    },
    noItemsHeaderLabel: {
      color: BLACK_100,
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      opacity: 0.7,
    },
    noItemsTextLabel: {
      color: BLACK_100,
      textAlign: 'center',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      opacity: 0.7,
    },
    finishedHeaderLabel: {
      color: BLACK_100,
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '1.625rem',
    },
    finishedTextLabel: {
      color: BLACK_100,
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.625rem',
    },
  },
}

const CopilotHeader = ({onClose}) => {
  return (
    <Stack direction={'column'} spacing={'1rem'} position={'fixed'} top={`calc(${APP_HEADER_HEIGHT} + 56px)`} zIndex={999} width={COPILOT_DRAWER_WIDTH} bgcolor={WHITE_100}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={HEADER_HEIGHT} paddingX={'1rem'} paddingTop={'1.25rem'} bgcolor={WHITE_100}>
        <Stack direction={'row'} my={'0.25rem'} spacing={'0.5rem'} alignItems={'center'}>
          <img src={AiStarIcon} alt={''}/>
          <Typography sx={styles.header.label}>AI assistant</Typography>
        </Stack>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{color: BLACK_100}} />
        </IconButton>
      </Stack>
      <Divider orientation={'horizontal'} sx={{width: '100%', opacity: 100}} />
    </Stack>
  )
}

CopilotHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const CopilotContent = ({documentId}) => {
  const {copilotV2CardLayout} = useFlags();
  const copilot = useSelector(state => state.copilot);

  const [totalOpenItems, setTotalOpenItems] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [openItem, setOpenItem] = useState(0);
  const {footings: {isVisible: footingsIsVisible}, inconsistencies: {isVisible: inconsistenciesIsVisible}} = copilot;

  useEffect(() => {
    let _numOpenItems = 0;
    let _totalOpenItems = 0;
    if (copilot.footings.isVisible) {
      _numOpenItems += _.get(copilot, 'footings.numOpenItems', 0);
      _totalOpenItems += _.get(copilot, 'footings.numItems', 0);
    }
    if (copilot.inconsistencies.isVisible) {
      _numOpenItems += _.get(copilot, 'inconsistencies.numOpenItems', 0);
      _totalOpenItems += _.get(copilot, 'inconsistencies.numItems', 0);
    }
    setTotalOpenItems(_numOpenItems);
    setTotalItems(_totalOpenItems);
    setHasError(_.get(copilot, 'footings.isError', false) || _.get(copilot, 'inconsistencies.isError', false));
    setIsProcessing(_.get(copilot, 'footings.isFetching', false) || _.get(copilot, 'inconsistencies.isFetching', false));
  }, [copilot]);

  useEffect(() => {
    setOpenItem(0);
  }, [footingsIsVisible, inconsistenciesIsVisible]);

  if (_.isNil(copilot) || _.isNil(copilot.footings) || _.isNil(copilot.inconsistencies)) {
    return null;
  }

  const renderError = () => {
    if (hasError) {
      return (
        <Stack direction={'column'} spacing={'1rem'} paddingX={'1.25rem'}>
          <Stack direction={'row'} gap={'0.75rem'} mx={'1rem'} padding={'0.75rem'} border={'1px solid #FFC738'} borderRadius={'0.5rem'} bgcolor={'#FFEEC3'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#FFC738'} height={'1.25rem'} minWidth={'1.25rem'} borderRadius={'50%'}>
              <ErrorOutlineIcon sx={{height: '1rem', width: '1rem'}} />
            </Box>
            <Typography sx={styles.body.errorLabel}>An error occurred while loading results. Please try again.</Typography>
          </Stack>
        </Stack>
      )
    }
  }

  const renderCards = () => {
    if (totalOpenItems === 0) {
      return (
        <Stack direction={'column'} gap={'0.5rem'} justifyContent={'center'} alignItems={'center'}>
          <Typography style={styles.body.noItemsHeaderLabel}>You have no open items</Typography>
          <Typography style={styles.body.noItemsTextLabel}> To ensure accuracy, review your report to see if any new items are found. </Typography>
        </Stack>
      )
    }
    if (totalItems >= 0 && totalOpenItems === 0) {
      return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <Box marginBottom={'0.625rem'}>
            <img src={ThumbsUpIcon} alt={''} height={'32px'} width={'32px'}/>
          </Box>
          <Typography style={styles.body.finishedHeaderLabel}>Shall we say, hooray!</Typography>
          <Typography style={styles.body.finishedTextLabel}>Your report is looking sharp.</Typography>
        </Stack>
      )
    }

    const _items = [];
    if (footingsIsVisible) {
      _items.push(..._.values(copilot.footings.openItems));
    }
    if (inconsistenciesIsVisible) {
      _items.push(..._.values(copilot.inconsistencies.openItems));
    }

    return (
      <Stack direction={'column'} flex={1} spacing={copilotV2CardLayout ? 0 : '0.5rem'}>
        {_.map(_.orderBy(_items, ['pageNumber'], ['asc']), (_item, _idx) => {
          if (copilotV2CardLayout) {
            return (
              <Card documentId={documentId} checkType={_item.type} setOpenItem={setOpenItem} openItem={openItem} idx={_idx} key={`${_idx}`} item={_item} isLoading={copilot[_item.type].isFetching} />
            )
          } else {
            return (
              <CardV1 documentId={documentId} checkType={_item.type} setOpenItem={setOpenItem} openItem={openItem} idx={_idx} key={`${_idx}`} item={_item} isLoading={copilot[_item.type].isFetching} />
            )
          }
        })}
      </Stack>
    )
  }

  const renderContent = () => {
    if (totalItems === 0 && totalOpenItems === 0) {
      return (
        <Stack direction={'column'} flex={1} spacing={'0.5rem'} width={'17.25rem'} alignItems={'center'} justifyContent={'center'} paddingBottom={isProcessing ? '5rem' : '1rem'}>
          <Typography style={styles.body.noResultsLabel}>No results to review.</Typography>
          <Typography style={styles.body.noResultsSublabel}>Suggestions will appear here when you run a review and items are found.</Typography>
        </Stack>
      )
    } else {
      return (
        <Stack direction={'column'} spacing={'1rem'} width={'100%'} flex={1} paddingX={'1.25rem'} paddingTop={'0.5rem'} paddingBottom={isProcessing ? '5rem' : '1rem'}>
          <Stack direction={'row'} spacing={'0.5rem'} alignItems={'center'}>
            <Typography style={styles.body.currentResults.label}>Current results</Typography>
            {!isProcessing && (
              <Chip label={totalOpenItems} sx={styles.body.currentResults.chip} />
            )}
            {isProcessing && (
              <CircularProgress sx={{color: '#043B43'}} size={'0.75rem'} />
            )}
          </Stack>
          {renderCards()}
          <Stack direction={'column'}>
            <Typography style={styles.body.footerLabel}>Copilot is in beta. Items found in review are suggestions and should be verified.</Typography>
          </Stack>
        </Stack>
      )
    }
  }

  const renderFooter = () => {
    if (isProcessing) {
      return (
        <Stack position={'fixed'} bottom={'0'} direction={'row'} spacing={'0.75rem'} paddingX={'1rem'} paddingY={'0.75rem'} minHeight={'4rem'} maxHeight={'4rem'} alignItems={'center'} bgcolor={'#DAF1F9'} borderTop={'2px solid #289DC8'} width={COPILOT_DRAWER_WIDTH}>
          <InfoOutlinedIcon sx={{color: '#043B34', fontSize: '1rem'}} />
          <Typography style={styles.footer.footerLabel}>Generating results. This can take up to 10 minutes.</Typography>
        </Stack>
      )
    }
  }

  return (
    <Stack direction={'column'} spacing={'1rem'} alignItems={'center'} width={'100%'} height={'100%'}>
      {renderError()}
      {renderContent()}
      {renderFooter()}
    </Stack>
  )
}

CopilotContent.propTypes = {
  documentId: PropTypes.string.isRequired,
}

const ScoreCard = ({numOpenItems = 0, numItems = 0, checkType, hasRun}) => {
  const _numCompletedItems = numItems - numOpenItems;
  if (checkType === 'inconsistencies') {
    if (!hasRun) {
      return (
        <Stack direction={'row'} spacing={'0.75rem'} alignItems={'center'}>
          <Typography style={styles.runCard.count}>--</Typography>
        </Stack>
      );
    } else {
      return (
        <Stack direction={'row'} spacing={'0.38rem'} alignItems={'center'}>
          {numOpenItems === 0 && (
            <TaskAltIcon sx={{color: '#2CC27E', fontSize: '1rem'}} />
          )}
          {numOpenItems > 0 && (
            <ErrorOutlineIcon sx={{color: '#D73E3E', fontSize: '1rem'}} />
          )}
          <Typography style={styles.runCard.count}>{numOpenItems}</Typography>
        </Stack>
      );
    }
  } else {
    return (
      <Stack direction={'row'} spacing={'0.75rem'} alignItems={'center'}>
        <Stack direction={'row'} spacing={'0.38rem'} alignItems={'center'}>
          <ErrorOutlineIcon sx={{color: '#D73E3E', fontSize: '1rem'}} />
          <Typography style={styles.runCard.count}>{numOpenItems === 0 && !hasRun ? '--' : numOpenItems}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={'0.38rem'} alignItems={'center'}>
          <TaskAltIcon sx={{color: '#2CC27E', fontSize: '1rem'}} />
          <Typography style={styles.runCard.count}>{_numCompletedItems === 0 && !hasRun ? '--' : _numCompletedItems}</Typography>
        </Stack>
      </Stack>
    )
  }
}

ScoreCard.propTypes = {
  numOpenItems: PropTypes.number,
  numItems: PropTypes.number,
  checkType: PropTypes.string.isRequired,
  hasRun: PropTypes.bool,
}

const CopilotRunCard = forwardRef(({label, checkType}, ref) => {
  const dispatch = useDispatch();
  const {documentId} = useParams();
  const copilotData = useSelector(state => state.copilot[checkType]);
  const [createJob, {data, isLoading, isSuccess, isError, error}] = useCreateJobMutation();
  const [getJobStatus] = useLazyGetJobStatusQuery();
  const [getJobResultSignedUrl] = useLazyGetJobResultUrlQuery();
  const [isProcessing, setIsProcessing] = useState(false);

  useImperativeHandle(ref, () => ({
    createJob: _onClick,
  }));

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        console.info('AI job created', {jobId: data.job_id, checkType});
        _checkJobStatus(data.job_id);
      } else if (isError) {
        console.error('Copilot job creation failed', {error});
        setIsProcessing(false);
      }
    }
  }, [isLoading, isSuccess, isError, data, error]);

  if (_.isNil(copilotData)) {
    return;
  }

  const _checkJobStatus = async (jobId) => {
    if (_.isNil(jobId)) {
      console.error('No job ID found', {jobId, checkType});
      dispatch(setPayload({
        checkType,
        isError: true,
        error: 'There was an error processing your request. Please try again later.',
        isFetching: false,
      }));
      setIsProcessing(false);
      return;
    }

    try {
      // // Temp code to skip AI
      // const _jobResultUrl = await getJobResultSignedUrl({jobId, checkType}).unwrap();
      // console.log('---> Signed URL', _jobResultUrl);
      // const _jobResultData = (await axios.get(_jobResultUrl)).data;
      // dispatch(setPayload({
      //   jobId,
      //   checkType,
      //   data: _jobResultData,
      //   isSuccess: true,
      //   isFetching: false,
      //   isError: false,
      //   error: null,
      // }));

      const _jobResult = await getJobStatus({jobId, checkType}).unwrap();
      if (_jobResult.status === 'completed') {
        const _jobResultUrl = await getJobResultSignedUrl({jobId, checkType}).unwrap();
        const _jobResultData = (await axios.get(_jobResultUrl)).data;
        dispatch(setPayload({
          checkType,
          jobId,
          data: _jobResultData,
          isSuccess: true,
          isFetching: false,
          isError: false,
          error: null,
        }));
      } else if (_jobResult.status === 'failed') {
        console.error('Copilot job failed', {jobId, checkType});
        dispatch(setPayload({
          checkType,
          isSuccess: false,
          isFetching: false,
          isError: true,
          error: _.get(_jobResult, 'error', 'There was an error processing your request. Please try again later.'),
        }));
      }
    } catch (_error) {
      console.error('Error processing job', {error: _error});
      dispatch(setPayload({
        checkType,
        isSuccess: false,
        isFetching: false,
        isError: true,
        error: _.get(_error, 'message', 'There was an error processing your request. Please try again later.'),
      }));
    } finally {
      setIsProcessing(false);
    }
  }

  const _onClick = () => {
    dispatch(setPayload({
      checkType,
      isSuccess: false,
      isFetching: true,
      isError: false,
      error: null,
    }));

    setIsProcessing(true);
    createJob({documentId, checkType});
    // if (checkType === 'footings') {
    //   _checkJobStatus('b55abc58-740d-4f99-8198-f6e482f6c4c5');
    // } else if (checkType === 'inconsistencies') {
    //   _checkJobStatus('d92d12c3-128c-43f6-969f-9154a77385cc');
    // }
  }

  const _getStateLabel = () => {
    if (isProcessing) {
      return 'Review in progress';
    } else if (!_.isNil(copilotData.asOf)) {
      return <>Last run <TimeAgo timestamp={copilotData.asOf} /></>;
    } else {
      return 'Check has not been run';
    }
  }

  const _setIsVisible = (isVisible) => () => {
    dispatch(setIsVisible({checkType, isVisible}));
  }

  return (
    <Stack direction={'column'} spacing={'0.75rem'} paddingY={'0.75rem'} paddingX={'1rem'} borderRadius={'0.5rem'} border={'1px solid #DDE0DE'} bgcolor={WHITE_100} minHeight={'5.5rem'} maxHeight={'5.5rem'} justifyContent={'space-between'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography style={styles.runCard.title}>{label}</Typography>
        <Stack direction={'row'} spacing={'0.75rem'}>
          {copilotData.isVisible && copilotData.numOpenItems > 0 && (
            <IconButton aria-label="cards visible" size={'small'} padding={'0.25rem'} sx={{color: '#043B34'}} onClick={_setIsVisible(false)}>
              <VisibilityOutlinedIcon sx={{fontSize: '1rem'}} />
            </IconButton>
          )}
          {!copilotData.isVisible && copilotData.numOpenItems > 0 && (
            <IconButton aria-label="cards hidden" size={'small'} padding={'0.25rem'} sx={{color: '#043B34', opacity: 0.5}} onClick={_setIsVisible(true)}>
              <VisibilityOffOutlinedIcon sx={{fontSize: '1rem'}} />
            </IconButton>
          )}
          <LoadingButton variant={'outlined'} sx={styles.runCard.button.styling} onClick={_onClick} loading={isProcessing}>{!isProcessing && (<Typography style={styles.runCard.button.label}>Run</Typography>)}</LoadingButton>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={'0.75rem'} height={'1.5rem'} flex={1}>
        <ScoreCard numOpenItems={_.get(copilotData, 'numOpenItems', 0)} numItems={_.get(copilotData, 'numItems', 0)} checkType={checkType} hasRun={!_.isNil(copilotData.asOf)} />
        <Typography style={{...styles.runCard.label, opacity: isProcessing ? 0.5 : 1}}>{_getStateLabel()}</Typography>
      </Stack>
    </Stack>
  )
});

CopilotRunCard.displayName = 'CopilotRunCard';

CopilotRunCard.propTypes = {
  label: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
}

const CopilotRunContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const _copilotData = useSelector(state => state.copilot);
  const _footingsRef = useRef();
  const _inconsistenciesRef = useRef();

  useEffect(() => {
    if (isLoading) {
      setIsLoading(_copilotData.footings.isFetching || _copilotData.inconsistencies.isFetching);
    }
  }, [_copilotData, isLoading]);

  const _onClick = () => {
    setIsLoading(true);
    if (!_.isNil(_footingsRef.current)) {
      _footingsRef.current.createJob();
    }
    if (!_.isNil(_inconsistenciesRef.current)) {
      _inconsistenciesRef.current.createJob();
    }
  }

  return (
    <Stack direction={'column'} spacing={'1rem'} width={'100%'} paddingX={'1.25rem'} paddingTop={`calc(${HEADER_HEIGHT} + 1rem)`}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
        <Typography style={styles.selection.text2}>Review checks</Typography>
        <LoadingButton variant={'outlined'} sx={styles.selection.button.styling} onClick={_onClick} loading={isLoading}>{!isLoading && (<Typography style={styles.selection.button.label}>Run all</Typography>)}</LoadingButton>
      </Stack>
      <CopilotRunCard label={'Footing'} checkType={'footings'} onClick={_onClick} ref={_footingsRef} />
      <CopilotRunCard label={'Inconsistencies'} checkType={'inconsistencies'} onClick={_onClick} ref={_inconsistenciesRef} />
    </Stack>
  )
}

const CopilotDrawer = ({open, onClose}) => {
  const dispatch = useDispatch();
  const {documentId} = useParams();
  const {copilot} = useSelector(state => state);

  useEffect(() => {
    if (_.isNil(copilot.footings) || _.isNil(copilot.inconsistencies)) {
      dispatch(reset());
    }
  }, [copilot]);

  useEffect(() => {
    if (copilot.documentId !== documentId) {
      dispatch(reset());
    }
  }, [documentId]);

  return (
    <Drawer
      anchor={'right'}
      open={open}
      variant={'persistent'}
      onClose={onClose}
      sx={{zIndex: 998}}
      PaperProps={{sx: {paddingTop: '6.5rem', background: WHITE_100, borderLeft: '1px solid #DDE0DE'}}}
    >
      <Stack direction={'column'} sx={{width: COPILOT_DRAWER_WIDTH}} spacing={'1rem'} height={'100%'}>
        <CopilotHeader onClose={onClose}/>
        <CopilotRunContainer />
        <CopilotContent documentId={documentId} />
      </Stack>
    </Drawer>
  )
}

CopilotDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CopilotDrawer;
