import React, {useState, useEffect, useCallback, useMemo} from 'react'
import PropTypes from 'prop-types';

// Context
import {useDrawer} from '../../context/DrawerContext';

// Custom components
import CommonPageWrapper from '../../components/CommonPageWrapper';
import CommentComposer from '../../components/report-workspace/CommentComposer';
import CommentSelectionMenu from '../../components/report-workspace/CommentSelectionMenu';
import DocumentToolbar from '../../components/report-workspace/DocumentToolbar';
import Loading from '../../components/Loading';

// Tiptap
import {mergeAttributes} from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import UniqueID from '@tiptap-pro/extension-unique-id';
import {EditorContent, useEditor, useEditorState} from '@tiptap/react';
import {Indent} from '../../components/report-workspace/Indent';
import {PageExtension} from '../../components/report-workspace/PageExtension';
import {PagedDocument} from '../../components/report-workspace/PagedDocument';
import LinkedTextNodeView from '../../components/report-workspace/LinkedTextNodeView';
import {DocumentPasteHandler} from '../../components/report-workspace/DocumentPasteHandler';
import DocumentTableNodeView from '../../components/report-workspace/DocumentTable/DocumentTableNodeView';

// Collaboration
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationHistory from '@tiptap-pro/extension-collaboration-history';
import {SnapshotCompare} from '@tiptap-pro/extension-snapshot-compare';
// import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import {useSelf} from '../../liveblocks.config';
import {LiveblocksCommentsHighlight} from '../../components/report-workspace/CommentHighlight';
import {insertYDocUserMeta} from '../../components/report-workspace/DocumentHistory/utils';

// MUI
import {
  Box,
  Stack,
  useTheme,
} from '@mui/material';

// Utils
import {COPILOT_DRAWER_WIDTH} from '../../App';
import deepEqual from 'deep-equal'

const LazyCommentDrawer = React.lazy(() => import('../../components/report-workspace/CommentDrawer'));
const LazyHistoryDrawer = React.lazy(() => import('../../components/report-workspace/DocumentHistory/HistoryDrawer'));

const DocumentEditor = React.memo(({
  doc,
  provider,
  canEdit,
  contentToPrintRef,
  enterPrintMode,
  isDocumentLoaded,
  isPrinting,
  onPrintContentReady,
  flags,
}) => {
  const [isTableActive, setIsTableActive] = useState(false);
  const [selectionCount, setSelectionCount] = useState(0);

  const {drawerStates, isDrawerOpen, openDrawer, closeDrawer} = useDrawer();

  // History state
  const [isAutoVersioning, setIsAutoVersioning] = useState(false);
  const [historyDataFetched, setHistoryDataFetched] = useState(false);
  const [versions, setVersions] = useState([]);
  const [isLoadingVersionPreview, setIsLoadingVersionPreview] = useState(false);
  const [storedVersionContent, setStoredVersionContent] = useState('');
  const [storedDiffContent, setStoredDiffContent] = useState(null);

  const {name, color} = useSelf((me) => me.info);
  const theme = useTheme();

  const editorExtensions = useMemo(() => {
    return [
      PagedDocument,
      PageExtension.configure({
        bodyPadding: 46,
        bodyWidth: 816,
        headerHeight: 0,
        footerHeight: 0,
        bodyHeight: 1056,
      }),
      DocumentTableNodeView.configure({
        isPrinting,
      }),
      LinkedTextNodeView.configure({
        isPrinting,
      }),
      DocumentPasteHandler,
      StarterKit.configure({
        document: false,
        heading: false,
        history: false,
      }),
      Heading.extend({
        levels: [1, 2, 3],
        renderHTML ({node, HTMLAttributes}) {
          const level = this.options.levels.includes(node.attrs.level)
            ? node.attrs.level
            : this.options.levels[0];

          const classes = {
            1: 'text-huge',
            2: 'text-large',
            3: 'text-small',
          };

          return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
              class: `${classes[level]}`,
            }),
            0,
          ];
        },
      }).configure({levels: [1, 2, 3]}),
      Underline,
      Indent,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'right', 'center'],
      }),
      LiveblocksCommentsHighlight.configure({
        HTMLAttributes: {
          class: 'comment-highlight',
        },
      }),
      UniqueID.configure({
        types: ['heading', 'paragraph', 'orderedList', 'bulletList', 'listItem', 'table', 'horizontalRule', 'linkedText'],
      }),
      Collaboration.configure({
        document: doc,
      }),
      CollaborationHistory.configure({
        provider,
        onUpdate: data => {
          setVersions(data.versions);
          setIsAutoVersioning(data.versioningEnabled);
          setHistoryDataFetched(true);
        },
      }),
      SnapshotCompare.configure({
        provider,
      }),
      // Disabled until pagination duplication bug is fixed
      // CollaborationCursor.configure({
      //   provider,
      //   user: {
      //     name,
      //     color,
      //   },
      // }),
    ];
  }, [isPrinting, doc, provider, name, color]);

  const editor = useEditor({
    extensions: editorExtensions,
    editable: canEdit,
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    onSelectionUpdate: ({editor}) => {
      if (!editor.state.selection.empty) {
        setSelectionCount(prev => prev + 1);
      }
    },
  }, [isPrinting]);

  const editorState = useEditorState({
    editor,
    selector: ({editor: editorInstance}) => ({
      showComposer: editorInstance.storage.commentHighlight.showComposer,
    }),
    equalityFn: deepEqual,
  });

  const versionPreviewExtensions = useMemo(() => {
    return editorExtensions
      .filter(
        (extension) =>
          extension.name !== 'collaboration' &&
          extension.name !== 'collaborationCursor' &&
          extension.name !== 'collaborationHistory',
      )
      .map((extension) => {
        if (extension.name === 'commentHighlight') {
          return extension.configure({
            disableClick: true,
          });
        }

        if (extension.name === 'PageExtension') {
          return extension.configure({
            bodyPadding: 46,
            bodyWidth: 816,
            headerHeight: 0,
            footerHeight: 0,
            bodyHeight: 1056,
            showPages: !storedDiffContent,
          });
        }

        return extension;
      });
  }, [editorExtensions, storedDiffContent]);

  const versionPreviewEditor = useEditor({
    extensions: versionPreviewExtensions,
    editable: false,
    content: storedVersionContent || '',
    onCreate ({editor}) {
      if (storedDiffContent && !isPrinting) {
        setTimeout(() => {
          editor
            .chain()
            .showDiff(storedDiffContent.tr, {diffs: storedDiffContent.diffs})
            .run();
        }, 500)
      }
    },
  }, [isPrinting, storedDiffContent]);

  const addTableToPage = useCallback(() => {
    const defaultTableData = [
      ['', '', '', ''], // default merged header cell
      ['', '2024', '', '2023'], // default header cell
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
    ];

    const defaultTableDataJSON = JSON.stringify(defaultTableData);
    const defaultMergedCells = [{row: 0, col: 1, rowspan: 1, colspan: 3}];
    const defaultMergedCellsJSON = JSON.stringify(defaultMergedCells);

    const tableHTML = `<document-table table-data='${defaultTableDataJSON}' table-meta='{}' table-merged-cells='${defaultMergedCellsJSON}' table-size-multiplier='1'></document-table><p></p>`;

    editor
      .chain()
      .focus()
      .insertContent(tableHTML)
      .run();
  }, [editor]);

  useEffect(() => {
    if (!isAutoVersioning && historyDataFetched) {
      editor?.commands.toggleVersioning();
    }
  }, [editor, isAutoVersioning, historyDataFetched]);

  useEffect(() => {
    if (editor && isDocumentLoaded) {
      editor.commands.focus('start');
    }
  }, [editor, isDocumentLoaded]);

  useEffect(() => {
    setTimeout(() => {
      editor?.value?.view.dispatch(editor.value?.state.tr.setMeta('splitPage', true));
    }, 1000);
  }, []);

  useEffect(() => {
    const waitForEditorReady = async (editorInstance) => {
      if (editorInstance.state && editorInstance.state.doc.content.size > 0) {
        return Promise.resolve();
      }

      return new Promise(resolve => {
        const updateHandler = () => {
          if (editorInstance.state.doc.content.size > 0) {
            editorInstance.off('update', updateHandler);
            resolve();
          }
        };

        editorInstance.on('update', updateHandler);

        setTimeout(() => {
          editorInstance.off('update', updateHandler);
          resolve();
        }, 3000);
      });
    };

    const executePrintFlow = async () => {
      if (!isPrinting || !onPrintContentReady) return;
      if (editor) await waitForEditorReady(editor);
      if (versionPreviewEditor) await waitForEditorReady(versionPreviewEditor);

      if (drawerStates.history && storedDiffContent) {
        versionPreviewEditor
          .chain()
          .showDiff(storedDiffContent.tr, {diffs: storedDiffContent.diffs})
          .run();
      }

      onPrintContentReady();
    };

    executePrintFlow();
  }, [
    isPrinting,
    editor,
    versionPreviewEditor,
    onPrintContentReady,
    storedDiffContent,
    drawerStates.history,
  ]);

  useEffect(() => {
    const handlePointerDown = (event) => {
      const tableElement = event.target.closest('.handsontable');

      if (tableElement) {
        setIsTableActive(true);
      } else {
        setIsTableActive(false);
      }
    };

    document.addEventListener('mousedown', handlePointerDown);

    return () => {
      document.removeEventListener('mousedown', handlePointerDown);
    };
  }, []);

  useEffect(() => {
    const handleNewVersion = (data) => {
      const payload = JSON.parse(data.payload)
      if (payload.event === 'version.created') {
        insertYDocUserMeta(provider, payload.version, name);
      };
    };

    provider.on('stateless', handleNewVersion);

    return () => {
      provider.off('stateless', handleNewVersion);
    };
  }, []);

  const memoizedCommentSelectionMenu = useCallback(() => {
    if (drawerStates.history) {
      return <CommentSelectionMenu editor={versionPreviewEditor} skipShow={true} />
    } else {
      return <CommentSelectionMenu editor={editor} />
    }
  }, [editor, versionPreviewEditor, selectionCount, drawerStates.history]);

  const handleVersionPreviewLoading = useCallback(() => {
    if (!isLoadingVersionPreview) {
      setIsLoadingVersionPreview(true);

      setTimeout(() => {
        setIsLoadingVersionPreview(false);
      }, 2000);
    }
  }, []);

  const contentWrapperStyle = {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    px: '1.8675rem',
    overflow: 'scroll',
    scrollPaddingBlockStart: '20%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...((isDrawerOpen) && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: COPILOT_DRAWER_WIDTH,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  };

  return (
    <>
      <DocumentToolbar
        doc={doc}
        editor={editor}
        readOnly={!canEdit}
        addTable={addTableToPage}
        enterPrintMode={enterPrintMode}
        isTableActive={isTableActive}
      />

      <Box mt={'8rem'} sx={contentWrapperStyle}>
        <CommonPageWrapper>
          <Stack direction='column' alignItems='center' flex={1} sx={{position: 'relative'}}>
            <Box sx={{display: 'grid', flexGrow: 1, borderRadius: '8px', placeItems: 'center'}}>
              {drawerStates.history
                ? (
                  <Box sx={{opacity: isLoadingVersionPreview ? 0.5 : 1}}>
                    <EditorContent ref={contentToPrintRef} editor={versionPreviewEditor} />
                  </Box>
                  )
                : (
                  <Box sx={{opacity: !isDocumentLoaded ? 0.8 : 1, pointerEvents: !isDocumentLoaded ? 'none' : 'auto'}}>
                    <EditorContent ref={contentToPrintRef} editor={editor} />
                  </Box>
                  )
              }
            </Box>

            {isLoadingVersionPreview && (
              <Loading
                loading={true}
                loadingProps={{size: '46'}}
                containerProps={{
                  position: 'fixed',
                  zIndex: 1000,
                  top: 0,
                }}
              />
            )}
          </Stack>

          {memoizedCommentSelectionMenu()}
        </CommonPageWrapper>

        {editorState.showComposer && (
          <CommentComposer
            editor={editor}
            containerRef={contentToPrintRef}
            isDrawerOpen={isDrawerOpen}
            openComments={() => openDrawer('comments')}
          />
        )}
      </Box>

      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyCommentDrawer
          editor={editor}
          open={drawerStates.comments}
          onClose={() => closeDrawer('comments')}
          readOnly={!canEdit}
        />
      </React.Suspense>

      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyHistoryDrawer
          parentEditor={editor}
          versionPreviewEditor={versionPreviewEditor}
          ydoc={doc}
          provider={provider}
          open={drawerStates.history}
          onClose={() => closeDrawer('history')}
          versions={versions}
          setVersionLoading={handleVersionPreviewLoading}
          setStoredVersionContent={setStoredVersionContent}
          setStoredDiffContent={setStoredDiffContent}
          flags={flags}
        />
      </React.Suspense>
    </>
  );
});

DocumentEditor.displayName = 'DocumentEditor';

DocumentEditor.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  contentToPrintRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  doc: PropTypes.object.isRequired,
  enterPrintMode: PropTypes.func.isRequired,
  isDocumentLoaded: PropTypes.bool.isRequired,
  isPrinting: PropTypes.bool.isRequired,
  flags: PropTypes.object.isRequired,
  onPrintContentReady: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
};

export default DocumentEditor;
