export const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const month = new Intl.DateTimeFormat('en-US', {month: 'short'}).format(date);
  const day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(date);

  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `${month} ${day}, ${hours}:${minutes} ${ampm}`;
}

export const insertYDocVersionMeta = (ydoc, versionId, key, value) => {
  ydoc.transact(() => {
    const versionsArray = ydoc.getArray('__tiptapcollab__versions');
    const versionIndex = versionsArray.toArray().findIndex((version) => version.version === versionId);
    if (versionIndex !== -1) {
      const version = versionsArray.get(versionIndex);
      version[key] = value;
      versionsArray.delete(versionIndex, 1);
      versionsArray.insert(versionIndex, [version]);
    }
  })
}

export const insertYDocUserMeta = (provider, versionId, userName) => {
  const versionsArray = provider.configuration.document.getArray('__tiptapcollab__versions');
  const versionIndex = versionsArray.toArray().findIndex((version) => version.version === versionId);
  if (versionIndex !== -1) {
    provider.configuration.document.getArray(`version_to_user_${versionIndex}`).push([String(userName)])
  }
}

// Hack to force a delta in yjs so we can always generate named versions
// This does not alter the outputted JSON content
export const touchEditor = (editor) => {
  editor.chain().focus().insertContent('\u200B').run();
};

// Due to our pagination moving content between pages in fragments
// Those fragments are reflected in fragmentized diffs. This function
// attempts to merge those fragments that belong together based on their positioning
// such that they can be matched with the inverse operation and filtered out
// const mergeConsecutiveDiffs = (diffs) => {
//   const mergedDiffs = [];
//   let currentInsert = null;
//   let currentDelete = null;
//
//   diffs.forEach((item) => {
//     const {type, from, to} = item;
//
//     const itemContentText = item.content.content[0].text;
//     const itemContentType = item.content.content[0].type.name;
//
//     if (type === 'inline-insert' && itemContentType === 'text') {
//       if (currentInsert && currentInsert.to === from) {
//         currentInsert.content.content[0].text += itemContentText;
//         currentInsert.to = to;
//       } else {
//         if (currentInsert) {
//           mergedDiffs.push(currentInsert);
//         }
//         currentInsert = {...item};
//       }
//     } else if (type === 'inline-delete' && itemContentType === 'text') {
//       if (currentDelete && currentDelete.to === from) {
//         currentDelete.content.content[0].text += itemContentText;
//         currentDelete.to = to;
//       } else if (currentDelete && currentDelete.from === from) {
//         currentDelete.content.content[0].text += itemContentText;
//         currentDelete.to = Math.max(currentDelete.to, to);
//       } else {
//         if (currentDelete) {
//           mergedDiffs.push(currentDelete);
//         }
//         currentDelete = {...item};
//       }
//     } else {
//       if (currentInsert) {
//         mergedDiffs.push(currentInsert);
//         currentInsert = null;
//       }
//       if (currentDelete) {
//         mergedDiffs.push(currentDelete);
//         currentDelete = null;
//       }
//       mergedDiffs.push(item);
//     }
//   });
//
//   if (currentInsert) mergedDiffs.push(currentInsert);
//   if (currentDelete) mergedDiffs.push(currentDelete);
//
//   return mergedDiffs;
// };

export const filterOutPaginationDiffs = (diffs) => {
  const insertMap = new Map();
  const deleteMap = new Map();

  const normalizeText = (text) => {
    return text
      .replace(/\s+/g, ' ')
      .trim()
      .replace(/\.$/, '');
  };

  const collectTextContent = (content) => {
    let texts = [];
    content.forEach(block => {
      if (block?.text) {
        texts.push(block.text);
      }
      if (block.content) {
        texts = texts.concat(collectTextContent(block.content));
      }
    });
    return texts;
  };

  // const isEmptyParagraph = (content) => {
  //   const node = content.content[0];
  //   return node?.type?.name === 'paragraph' && node?.textContent === '';
  // };

  const isPageDiff = (content) => {
    const node = content.content[0];
    return node?.type?.name === 'page';
  };

  const isTableNode = (node) => {
    return node?.type?.name === 'table' && node?.attrs?.id;
  };

  const getTableId = (content) => {
    const node = content.content[0];
    if (isTableNode(node)) {
      return node.attrs.id;
    }
    return null;
  };

  // Filter out empty paragraphs and page-level diffs
  const filteredDiffs = diffs.filter(item => {
    const {type, content} = item;
    // if ((type === 'block-insert' || type === 'block-delete') && isEmptyParagraph(content)) {
    //   return false;
    // }

    if (type === 'block-update' && isPageDiff(content)) {
      return false;
    }

    return true;
  });

  filteredDiffs.forEach(item => {
    const {type, content} = item;
    const textContents = collectTextContent(content);
    const tableId = getTableId(content);

    if (tableId) {
      if (type === 'block-insert') {
        insertMap.set(`TABLE_BLOCK_${tableId}`, true);
      } else if (type === 'block-delete') {
        deleteMap.set(`TABLE_BLOCK_${tableId}`, true);
      }
    } else if (textContents.length > 0) {
      if (type === 'block-insert' || type === 'inline-insert') {
        textContents.forEach(text => insertMap.set(normalizeText(text), true));
      } else if (type === 'block-delete' || type === 'inline-delete') {
        textContents.forEach(text => deleteMap.set(normalizeText(text), true));
      }
    }
  });

  const textsToRemove = new Set();
  for (const key of insertMap.keys()) {
    if (deleteMap.has(key)) {
      textsToRemove.add(key);
    }
  }

  return filteredDiffs.filter(item => {
    const {content} = item;
    const tableId = getTableId(content);

    if (tableId) {
      return !textsToRemove.has(`TABLE_BLOCK_${tableId}`);
    }

    const textContents = collectTextContent(content);
    return !textContents.some(text => textsToRemove.has(normalizeText(text)));
  });
};

export const getAttributeDiffFromDecorations = (decorations) => {
  if (!Array.isArray(decorations) || decorations.length === 0) {
    return {before: null, after: null};
  }

  const firstDecoration = decorations[0];
  const diff = firstDecoration?.type?.spec?.diff;
  if (!diff || diff.type !== 'inline-update') {
    return {before: null, after: null};
  }

  const before = diff.previousAttributes || {};
  const after = diff.attributes || {};

  return {before, after};
}
