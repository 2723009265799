import {mergeAttributes, Node} from '@tiptap/core';
import {getId} from './page-utils';

export const Page = Node.create({
  priority: 2,
  name: 'page',
  content: 'block*',
  group: 'block',
  isolating: true,
  selectable: false,

  addOptions () {
    return {
      footerHeight: 48,
      headerHeight: 48,
      bodyHeight: 0,
      bodyWidth: 0,
      bodyPadding: 0,
      showPages: true,
      SystemAttributes: {},
    };
  },

  addAttributes () {
    return {
      HTMLAttributes: {},
      pageNumber: {
        default: 1,
      },
      id: {
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
    };
  },

  addCommands () {
    return {
      scrollPageIntoView: (pageId) => ({editor, view}) => {
        let pagePos = null;

        editor.state.doc.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
          if (node.type.name === 'page' && node.attrs.id === pageId) {
            pagePos = pos;
            return false;
          }
        });

        if (pagePos !== null) {
          const domNode = view.nodeDOM(pagePos);

          if (domNode) {
            domNode.scrollIntoView({behavior: 'smooth', block: 'start'});
            return true;
          }
        }

        return false;
      },
    };
  },

  parseHTML () {
    return [
      {
        tag: 'page',
      },
    ];
  },

  renderHTML ({node, HTMLAttributes}) {
    const pid = getId();
    if (!node.attrs.id) {
      node.attrs.id = pid;
    }
    return ['page', mergeAttributes(HTMLAttributes, {'data-id': pid}), 0];
  },

  addNodeView () {
    const options = this.options;
    return defaultPageViewRenderer(options);
  },
});

export function defaultPageViewRenderer (options) {
  return (props) => {
    return new PageView(props, options);
  };
}

export class PageView {
  constructor (props, options) {
    this.options = options;
    this.props = props;
    this.dom = document.createElement('div');
    this.contentDOM = document.createElement('div');
    this.footerDOM = document.createElement('div');
    this.buildDom();
    this.buildContentDOM();
    if (options.showPages) this.buildFooterDOM();
  }

  buildDom () {
    const {node} = this.props;
    const classes = ['text-editor', 'relative'];
    if (this.options.showPages) {
      classes.unshift('Page');
    } else {
      classes.unshift('AutoPage');
    }
    this.dom.setAttribute('class', classes.join(' '));
    this.dom.setAttribute('style', 'max-width:' + this.options.bodyWidth + 'px;width:' + this.options.bodyWidth + 'px;' + 'background-color:white;');
    this.dom.setAttribute('id', node.attrs.id);
    if (classes.includes('AutoPage') && !classes.includes('Page')) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = `
        @media print {
          @page {
            margin-horizontal: 1mm;
            margin-vertical: 3mm;
          }
        }
      `;
      document.head.appendChild(styleElement);
    }
    // Uncomment to disable right click context menu on pages
    // this.dom.oncontextmenu = () => false;
  }

  buildContentDOM () {
    this.contentDOM.classList.add('PageContent');
    this.contentDOM.setAttribute('style', 'min-height: ' + this.options.bodyHeight + 'px;padding:' + this.options.bodyPadding + 'px;');
    this.dom.append(this.contentDOM);
  }

  buildFooterDOM () {
    const {node} = this.props;
    this.footerDOM.classList.add('PageFooter');
    this.footerDOM.textContent = node.attrs.pageNumber;
    this.footerDOM.setAttribute('contenteditable', 'false');
    this.dom.append(this.footerDOM);
  }
}
