import React from 'react';
import {useOthers} from '../../liveblocks.config';
import {
  Avatar,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';
import {lighten} from '@mui/system';

const UserAvatars = () => {
  const users = useOthers();

  if (users.length === 0) {
    return null;
  }

  const getInitials = (name) => {
    const nameParts = name.split(' ');
    return nameParts[0][0] + nameParts[1][0];
  };

  const maxVisibleUsers = 3;
  const visibleUsers = users.slice(0, maxVisibleUsers);
  const remainingUsers = users.slice(maxVisibleUsers);
  const remainingUsersCount = remainingUsers.length;

  return (
    <Box display="flex" alignItems="center">
      {visibleUsers.map((user, index) => (
        <Tooltip
          key={user.connectionId}
          title={<Typography sx={{p: '5px'}} fontSize={12} fontWeight={700}>{user.info.name}</Typography>}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                borderRadius: '4px',
                backgroundColor: '#27353A',
                py: 0,
                px: 0.2,
              },
            },
          }}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
              bgcolor: user.info.color,
              color: 'white',
              fontSize: 10,
              fontWeight: 700,
              textTransform: 'uppercase',
              marginLeft: index !== 0 ? '-6px' : '0px',
              zIndex: index + 1,
              border: '2px solid white',
            }}
          >
            {getInitials(user.info.name)}
          </Avatar>
        </Tooltip>
      ))}

      {remainingUsersCount > 0 && (
        <Tooltip
          title={
            <Box sx={{p: '5px'}}>
              {remainingUsers.map((user) => (
                <Box key={user.connectionId} display="flex" alignItems="center" mb={1}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: user.info.color,
                      color: 'white',
                      fontSize: 11,
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      marginRight: 1,
                      border: `2px solid ${lighten(user.info.color, 0.3)}`,
                    }}
                  >
                    {getInitials(user.info.name)}
                  </Avatar>
                  <Typography fontSize={11} fontWeight={700} sx={{color: 'white'}}>
                    {user.info.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                borderRadius: '4px',
                backgroundColor: '#27353A',
                py: 0.5,
                px: 1,
              },
            },
          }}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
              bgcolor: '#DDE0DE',
              fontWeight: 700,
              textTransform: 'uppercase',
              marginLeft: '-6px',
              zIndex: maxVisibleUsers + 1,
              border: '2px solid white',
            }}
          >
            <Typography sx={{color: '#0F1E24', opacity: 0.6, fontSize: 12, fontWeight: 700}}>
              {`+${remainingUsersCount}`}
            </Typography>
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

export default UserAvatars;
