import React from 'react';
import CommonPageWrapper from '../CommonPageWrapper';
import {AppBar, Box, Stack, Toolbar} from '@mui/material';
import {APP_HEADER_HEIGHT, GREY_70, WHITE_100} from '../../App';

const EmptyToolbar = () => {
  const toolbarStyle = {
    height: '100%',
    minHeight: APP_HEADER_HEIGHT + '!important',
    borderBottom: '1px solid #DDE0DE',
    background: WHITE_100,
    alignItems: 'center',
  };

  return (
    <AppBar
      position='fixed'
      sx={{
        boxShadow: 'none',
        backgroundColor: GREY_70,
        marginTop: APP_HEADER_HEIGHT,
        height: 56,
        zIndex: 999,
      }}>
      <Toolbar sx={toolbarStyle}>
      </Toolbar>
    </AppBar>
  );
};

const EmptyDocumentLoading = () => {
  const contentWrapperStyle = {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    px: '1.8675rem',
  };

  return (
    <>
      <EmptyToolbar />

      <Box mt={'8rem'} sx={contentWrapperStyle}>
        <CommonPageWrapper>
          <Stack direction='column' alignItems='center' flex={1} sx={{position: 'relative'}}>
            <Box sx={{display: 'grid', flexGrow: 1, borderRadius: '8px', placeItems: 'center'}}>
              <div className='Page'></div>
            </Box>
          </Stack>
        </CommonPageWrapper>
      </Box>
    </>
  );
};

export default EmptyDocumentLoading;
