import {Mark, mergeAttributes} from '@tiptap/core';
import {Plugin, PluginKey} from '@tiptap/pm/state';
import {highlightEvent} from './comment-utils';

export const LiveblocksCommentsHighlight = Mark.create({
  name: 'commentHighlight',

  addOptions () {
    return {
      HTMLAttributes: {},
    };
  },

  addStorage () {
    return {
      showComposer: false,
      currentHighlightId: null,
      activeHighlightId: null,
      clickedHighlightId: null,
      disableClick: false,
    };
  },

  addCommands () {
    return {
      setCommentHighlight: function (attributes) {
        return ({commands}) => {
          this.storage.currentHighlightId = attributes.highlightId;
          this.storage.showComposer = true;
          return commands.setMark(this.name, attributes);
        };
      }.bind(this),

      toggleCommentHighlight: function (attributes) {
        return ({commands}) => {
          return commands.toggleMark(this.name, attributes);
        };
      }.bind(this),

      unsetCommentHighlight: function () {
        return ({commands}) => {
          return commands.unsetMark(this.name);
        };
      }.bind(this),
    };
  },

  addAttributes () {
    return {
      highlightId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-highlight-id'),
        renderHTML: (attributes) => {
          if (!attributes.highlightId) {
            return;
          }

          return {
            'data-highlight-id': attributes.highlightId,
          };
        },
      },

      state: {
        default: 'complete',
        parseHTML: (element) => element.getAttribute('data-state'),
        renderHTML: (attributes) => {
          if (!attributes.state) {
            return {};
          }

          return {
            'data-state': attributes.state,
          };
        },
      },

      color: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          return {
            style: `--commentHighlightColor: ${attributes.color}; background-color: var(--commentHighlightColor); color: inherit`,
          };
        },
      },
    };
  },

  parseHTML () {
    return [
      {
        tag: 'mark',
        priority: 51,
      },
    ];
  },

  renderHTML ({HTMLAttributes}) {
    const highlightId = HTMLAttributes?.['data-highlight-id'] || null;
    const elem = document.createElement('mark');

    Object.entries(
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-selected': 'false',
      }),
    ).forEach(([attr, val]) => elem.setAttribute(attr, val));

    const handlePointerEnter = (event) => {
      const targetIsCurrentMark =
        event.target instanceof HTMLElement
          ? event.target === elem || elem.contains(event.target)
          : false;
      elem.dataset.selected = targetIsCurrentMark ? 'true' : 'false';

      if (!this.editor) {
        return;
      }

      if (targetIsCurrentMark) {
        highlightEvent(highlightId, false);
        this.editor.storage.commentHighlight.activeHighlightId = highlightId;
      }
    };

    const handlePointerLeave = (event) => {
      elem.dataset.selected = 'false';

      if (!this.editor) {
        return;
      }

      highlightEvent(null, false);
      this.editor.storage.commentHighlight.activeHighlightId = null;
    };

    const handleClick = (event) => {
      const targetIsCurrentMark =
        event.target instanceof HTMLElement
          ? event.target === elem || elem.contains(event.target)
          : false;
      elem.dataset.selected = targetIsCurrentMark ? 'true' : 'false';

      if (!this.editor || this.options.disableClick) {
        return;
      }

      if (targetIsCurrentMark) {
        highlightEvent(highlightId, true);
        this.editor.storage.commentHighlight.clickedHighlightId = highlightId;
      }
    };

    elem.onpointerenter = handlePointerEnter;
    elem.onpointerleave = handlePointerLeave;
    elem.onclick = handleClick;

    return elem;
  },

  // Unset the comment highlight if the cursor is at the end of the highlighted text and types
  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey('unsetHighlightOnCursorMove'),
        view: (view) => {
          const {editor} = this;
          return {
            update: (view, prevState) => {
              const {selection} = view.state;
              const {from, empty} = selection;

              if (empty && from !== prevState.selection.from) {
                const marks = view.state.storedMarks || view.state.selection.$from.marks();
                const isCommentHighlightActive = marks.some((mark) => mark.type.name === 'commentHighlight');

                const $pos = view.state.selection.$from;
                const markEnd = $pos.nodeAfter && $pos.nodeAfter.isText ? $pos.end() : $pos.pos;

                if (isCommentHighlightActive && from === markEnd) {
                  editor.commands.unsetCommentHighlight();
                }
              }
            },
          };
        },
      }),
    ];
  },
});
